import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Fade } from 'react-awesome-reveal';
import ColorBlock from '../ColorBlock';
import Slant from '../Slant';
import ThreeCol from '../ThreeCol';
import TitleSection from '../TitleSection';

type Props = {
	padTop?: boolean;
	title?: string;
	ids?: number[];
};

const SpaceSquares: React.FC<Props> = ({
	padTop = true,
	title = "See what's going on in your TownSq",
	ids,
}) => {
	const { allSpaces } = useStaticQuery<GatsbyTypes.SpaceSquaresQuery>(graphql`
		query SpaceSquares {
			allSpaces: allWordpressWpSpace {
				edges {
					node {
						wordpress_id
						title
						slug
						acf {
							title
							subtitle
							map_image {
								source_url
							}
						}
					}
				}
			}
		}
	`);

	const spaces = ids?.map((id) =>
		allSpaces.edges.find(({ node }) => node.wordpress_id === id)
	);

	if (!spaces?.length) return null;

	return (
		<TitleSection
			padTop={padTop}
			title={title}
			containerSize="sm"
			beforeContainer={<Slant ltr />}
			content={
				<ThreeCol>
					{spaces?.filter(Boolean).map(({ node }) => (
						<Fade key={node.slug}>
							<ColorBlock
								title={node.title}
								text={node.acf.title}
								subtext={node.acf.subtitle}
								image={node.acf?.map_image?.source_url}
								url={`/spaces/${node.slug}`}
								className={'transluscent'}
							/>
						</Fade>
					))}
				</ThreeCol>
			}
		/>
	);
};

export default SpaceSquares;
