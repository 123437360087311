import React, { Fragment } from 'react';
import { Link } from 'gatsby';
import { Fade } from 'react-awesome-reveal';
import {
	CentralTextBlock,
	FlipCard,
	Hero,
	MetaData,
	NewsCard,
	TitleSection,
} from '../components';
import SpaceSquares from '../components/SpaceSquares';
import ThreeCol from '../components/ThreeCol';

const Spaces = ({
	pageContext: { title, acf, recentEvents, featuredMembers },
}) => {
	const members = featuredMembers && featuredMembers.filter(Boolean);

	let spaces =
		acf.spaces.spaces && !!acf.spaces.spaces.length
			? acf.spaces.spaces
					.filter(({ space }) => space && space.wordpress_id)
					.map(({ space }) => space.wordpress_id)
			: null;

	return (
		<Fragment>
			<MetaData
				title={acf.meta_title || title}
				desciption={acf.meta_description}
			/>

			<Hero
				images={acf.header.header_images}
				title={acf.header.header_title}
				logo={acf.header.header_logo && acf.header.header_logo.source_url}
				link={acf.header.header_link}
				linkText={acf.header.header_link_text}
				linkPopup={acf.header.header_link_popup}
			/>

			<CentralTextBlock title={acf.grey_box.title} text={acf.grey_box.text} />

			{spaces && !!spaces.length && (
				<SpaceSquares title={acf.spaces.title} ids={spaces} />
			)}

			{recentEvents && !!recentEvents.length && (
				<TitleSection
					title={'Events'}
					color={'grey'}
					content={
						<ThreeCol>
							{recentEvents.map((event) => {
								return (
									<Fade key={event.name}>
										<NewsCard
											title={event.title}
											text={event.excerpt}
											image={event.acf.image && event.acf.image.source_url}
											link={`/event/${event.slug}`}
										/>
									</Fade>
								);
							})}
						</ThreeCol>
					}
				/>
			)}

			{members && !!members.length && (
				<TitleSection
					title={'Community'}
					// color={color}
					content={
						<div className="card-row w-row">
							{members.map(
								({
									title,
									content,
									excerpt,
									slug,
									featured_media: { source_url },
								}) => (
									<Fade key={title}>
										<div className="w-col w-col-4 community-card">
											<FlipCard
												front={() => (
													<div className={'profile-card-content'}>
														<h2 className="profile-card-title">
															<Link to={'/news/' + slug}>{title}</Link>
														</h2>
														<Link to={'/news/' + slug}>
															<img
																className={`profile-img-wrapper-1 profile-card-content__img ${
																	source_url
																		? ' profile-card-content__img--no-bg'
																		: ''
																}`}
																src={source_url}
																width={500}
															/>
														</Link>
														<div
															className="profile-card-p"
															dangerouslySetInnerHTML={{
																__html: excerpt,
															}}
														/>
														<Link
															to={'/news/' + slug}
															className="profile-card-button w-button"
														>
															Read more
														</Link>
													</div>
												)}
											/>
										</div>
									</Fade>
								)
							)}
						</div>
					}
				/>
			)}
		</Fragment>
	);
};

export default Spaces;
